<template>
    <div class="relative pl-5">
        <span
            class="circle"
            :class="circleColorClasses"
        >
            <icon
                v-if="value"
                name="checkmark"
                class="w-2 h-2"
            />
            <icon
                v-else
                name="close"
                class="w-2 h-2"
            />
        </span>
        <span
            class="text-xl font-sofia font-medium leading-none"
            :class="{ 'text-gray-400': !value }"
        >{{ text }}</span
        >
    </div>
</template>

<script>
import Icon from '@/components/ui/Icon';

export default {
    name: 'CheckCrossText',
    components: { Icon },
    props: {
        value: {
            type: Boolean,
            required: true,
        },

        text: {
            type: String,
            required: true,
        },
    },

    computed: {
        circleColorClasses() {
            return this.value ? 'border-green-600 text-green-600 bg-green-300' : 'border-gray-400 text-gray-400 bg-gray-200';
        },
    },
};
</script>

<style scoped>
.circle {
    @apply absolute left-0 top-2 inline-flex items-center justify-center w-3 h-3 border rounded-full;
}
</style>
