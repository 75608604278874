<template>
    <ContentArea
        ref="messagesBlock"
        content-class="mx-9"
        :watch-for-scroll="true"
        :loading="loadingPrevious"
        @reachedTop="loadPreviousMessages"
    >
        <template v-slot:default>
            <text-collapse
                :text="description"
                class="mb-4"
            />
            <div
                v-for="(message, index) in messages"
                :key="message.id"
                :class="{ 'bg-green-100': isUnread(index) }"
                class="pb-2"
            >
                <div
                    v-if="message.id === firstUnreadId"
                    class="bg-green-100 text-gray-400 text-sm py-4 flex justify-center items-center"
                >
                    {{ $t('sreq.feed.unread_messages') }}
                </div>
                <div
                    v-if="index === 0 || messages[index - 1].day !== message.day"
                    class="flex justify-center py-4"
                >
                    <icon
                        name="clock"
                        class="inline-block w-3 h-3 mr-1 text-black"
                    />
                    <span class="text-sm text-gray-500 font-ffdin font-medium">
                        {{ message.day }}
                    </span>
                </div>
                <Message
                    :id="`message-${message.id}`"
                    :message="message"
                    :unread="isUnread(index)"
                    @retry="retryMessageSending(message)"
                    @delete="deleteFailedMessage(message)"
                />
            </div>
        </template>

        <template v-slot:footer>
            <div
                v-if="messagingAllowed === true"
                class="mx-9 mb-8"
            >
                <MessageComposer @submit="handleMessageSubmission" />
            </div>
            <MessageBox
                v-if="messagingAllowed === false"
                leadingIcon="send"
            >{{ $t('sreq.feed.messaging_disabled') }}</MessageBox
            >
        </template>
    </ContentArea>
</template>

<script>
import ContentArea from '@/components/ui/ContentArea';
import CommunityTimezoneMixin from '@/mixins/CommunityTimezoneMixin';
import MessageComposer from '@/components/sreq/MessageComposer';
import Icon from '@/components/ui/Icon';
import Message from '@/components/sreq/feed/Message';
import {
    CHAT_ACTION_WRITE,
    MESSAGE_STATUS_FAILED,
    MESSAGE_STATUS_PENDING,
    MESSAGE_STATUS_RECEIVED,
    MESSAGE_TYPE_SIMPLE,
    USER_TYPE_RESIDENT,
} from '@/views/serviceRequests/constants';
import { mapGetters } from 'vuex';
import EventBus from '@/utils/EventBus';
import MessageBox from '@/components/ui/MessageBox';
import TextCollapse from '@/components/ui/TextCollapse';

export default {
    name: 'RequestFeed',
    components: {
        Message,
        Icon,
        MessageComposer,
        ContentArea,
        MessageBox,
        TextCollapse,
    },

    mixins: [CommunityTimezoneMixin],
    props: {
        chatId: {
            type: String,
            required: true,
        },

        description: {
            type: String,
            required: true,
        },
    },

    data() {
        return {
            messages: [],
            loadingPrevious: false,
            hasPrevious: true,
            firstUnreadId: null,
            messagingAllowed: undefined,
        };
    },

    computed: {
        ...mapGetters({
            cognitoId: 'auth/cognitoUserId',
        }),

        firstUnreadIndex() {
            if (!this.firstUnreadId) {
                return -1;
            }

            return this.messages.findIndex(message => message.id === this.firstUnreadId);
        },
    },

    mounted() {
        this.initializeMessages();

        this.$chatDataProvider.getOne('chats', { id: this.chatId }).then(({ data }) => {
            this.messagingAllowed =
                data.permissions.find(p => p.allowed && p.action === CHAT_ACTION_WRITE && p.target === 'public/NOTE/ORDINARY') !==
                    undefined &&
                data.permissions.find(p => p.allowed && p.action === CHAT_ACTION_WRITE && p.target === 'public/SIMPLE/*') !== undefined;
        });

        EventBus.on('wst-notification', this.processNotifications);
    },

    beforeUnmount() {
        EventBus.off('wst-notification', this.processNotifications);
    },

    methods: {
        prepareMessage(message) {
            const timestamp = this.parseDateTime(message.timestamp);
            return {
                ...message,
                status: MESSAGE_STATUS_RECEIVED,
                timestamp,
                day: this.prepareDayString(timestamp),
            };
        },

        prepareDayString(timestamp) {
            return this.formatDateTime(timestamp, 'MM/DD/YYYY');
        },

        isUnread(index) {
            return this.firstUnreadIndex >= 0 && index >= this.firstUnreadIndex;
        },

        async loadMessages(initial) {
            let options;

            if (!initial) {
                options = {
                    startFrom: this.messages[0].id,
                };
            }

            const response = await this.$chatDataProvider.getList('messages', {
                id: this.chatId,
                type: MESSAGE_TYPE_SIMPLE,
                ...options,
            });

            if (initial) {
                this.messages = [];
            }

            this.firstUnreadId = response.headers['x-quext-chat-1st-unread-msg-id'];

            const messages = response.data;
            this.hasPrevious = messages.length > 0;
            this.insertMessages(messages.map(message => this.prepareMessage(message)).reverse());

            const lastRead = this.messages.reduceRight((acc, message) => {
                acc[message.type] ??= message.id;

                return acc;
            }, {});

            if (initial) {
                this.$chatDataProvider.lastRead('messages', {
                    id: this.chatId,
                    data: lastRead,
                });
            }

            return response;
        },

        async initializeMessages() {
            const { data: messages } = await this.loadMessages(true);

            this.$nextTick(() => {
                const id = this.firstUnreadId || messages?.[0]?.id;
                if (id) {
                    this.scrollToMessage(id);
                }
            });
        },

        async loadPreviousMessages() {
            if (!this.hasPrevious) return;

            this.loadingPrevious = true;

            const messagesSectionElement = this.$refs.messagesBlock.$refs.pageContent;
            const oldScrollHeight = messagesSectionElement.scrollHeight;

            await this.loadMessages(false);

            this.$nextTick(() => {
                messagesSectionElement.scrollTop = messagesSectionElement.scrollHeight - oldScrollHeight;
                this.loadingPrevious = false;
            });
        },

        handleMessageSubmission(formValues) {
            this.firstUnreadId = null;

            const tempMessage = {
                type: MESSAGE_TYPE_SIMPLE,
                private: false,
                body: {
                    text: formValues.message,
                },
            };

            this.sendMessage(tempMessage);

            tempMessage.id = 'temp' + Math.random().toString(36).slice(2);
            tempMessage.status = MESSAGE_STATUS_PENDING;
            tempMessage.timestamp = this.parseDateTime(new Date());
            tempMessage.day = this.prepareDayString(tempMessage.timestamp);
            tempMessage.author = { id: { identityId: this.cognitoId, userType: USER_TYPE_RESIDENT } };
            this.insertMessages([tempMessage]);
            this.$nextTick(() => {
                this.scrollToMessage(tempMessage.id);
            });
        },

        sendMessage(message) {
            this.$chatDataProvider
                .create('messages', {
                    id: this.chatId,
                    data: message,
                })
                .then(({ data }) => {
                    this.messages[this.messages.findIndex(({ id }) => id === message.id)] = this.prepareMessage(data);
                })
                .catch(() => {
                    this.messages[this.messages.findIndex(({ id }) => id === message.id)] = {
                        ...message,
                        status: MESSAGE_STATUS_FAILED,
                    };
                });
        },

        retryMessageSending(message) {
            this.sendMessage(message);
            this.messages[this.messages.findIndex(({ id }) => id === message.id)] = {
                ...message,
                status: MESSAGE_STATUS_PENDING,
            };
        },

        deleteFailedMessage(message) {
            this.messages = this.messages.filter(m => m !== message);
        },

        insertMessages(messages) {
            this.messages = [...messages, ...this.messages].sort((a, b) => a.timestamp - b.timestamp);
        },

        scrollToMessage(id, smooth = false) {
            const messageElement = document.getElementById(`message-${id}`);
            messageElement.scrollIntoView({ behavior: smooth ? 'smooth' : 'auto' });
        },

        processNotifications({ channel, body }) {
            switch (channel) {
            case 'CHAT/new_chat_message':
                // to different chat
                if (body.chatId !== this.chatId) return;
                // my message (already displayed)
                if (body.author.id.identityId === this.cognitoId) return;

                this.insertMessages([this.prepareMessage(body)]);
                if (!this.firstUnreadId) {
                    this.firstUnreadId = body.id;
                }

                this.$nextTick(() => {
                    this.scrollToMessage(body.id);
                });
            }
        },
    },
};
</script>
