<template>
    <div class="message-composer">
        <div
            class="message-composer__message"
            style="min-height: 3.5rem"
        >
            <textarea
                id="sreq-notes-message"
                ref="message"
                v-model="message"
                class="message-composer__text-area"
                rows="1"
                :maxlength="maxLength"
            />
        </div>
        <div class="message-composer__tools">
            <div>{{ message.length }}/{{ maxLength }}</div>
            <icon
                name="send_2"
                class="message-composer__send-icon"
                :class="{ 'text-green-600': !empty, 'text-gray-300': empty }"
                @click="submit"
            />
        </div>
    </div>
</template>

<script>
import Icon from '@/components/ui/Icon';
import { MESSAGE_TYPE_SIMPLE } from '@/views/serviceRequests/constants';

export default {
    components: { Icon },

    emits: ['typeChanged', 'submit'],

    data() {
        return {
            MESSAGE_TYPE_SIMPLE,
            message: '',
            images: [],
            type: MESSAGE_TYPE_SIMPLE,
            maxLength: 5000,
        };
    },

    computed: {
        empty() {
            return this.message === '' && !this.images.length;
        },
    },

    methods: {
        attachImage(event) {
            const { files } = event.target;

            const acceptedImages = files.filter(file => file.size <= 10 * 1024 * 1024).slice(0, 5 - this.images.length);

            if (!acceptedImages.length) {
                return;
            }

            this.images.push(...acceptedImages);

            event.target.value = '';
        },

        submit() {
            if (this.empty) {
                return;
            }

            this.$emit('submit', {
                message: this.message,
            });

            this.message = '';
            this.$refs.message.focus();
        },
    },
};
</script>

<style scoped>
.message-composer__message {
    @apply border border-gray-200 bg-white flex items-center;

    border-radius: 0.5rem 0.5rem 0 0;
}

.message-composer__tools {
    @apply flex justify-end items-center h-10 px-4 text-purple-400 border border-purple-50 bg-purple-50;
    @apply font-sofia font-medium text-sm;

    border-top: 0px;
    border-radius: 0 0 0.5rem 0.5rem;
}

.message-composer__send-icon {
    @apply flex-shrink-0 w-6 h-6 ml-4 cursor-pointer text-purple-800;

    & :deep(.c) {
        fill: theme('colors.purple.800');
    }

    & :deep(.b) {
        stroke: theme('colors.purple.50');
    }
}

.message-composer__text-area {
    @apply font-sofia text-sm outline-none resize-none w-full px-8 py-1;

    word-break: break-word;
}
</style>
