<template>
    <div class="flex flex-col py-2">
        <div class="flex items-end">
            <div
                class="message"
                :class="{ my: isMy, 'from-property': fromPropertyStaff }"
            >
                <div
                    v-if="!isMy && unread"
                    class="point"
                />
                <div class="type-author flex items-baseline font-ffdin text-sm mb-1">
                    <icon
                        :name="typeIconName"
                        class="text-gray-500 inline-block h-2.5 w-2.5 mr-1.5"
                    />
                    <span class="author">
                        <span class="font-bold">{{ message.author.nickname }},</span>&nbsp;
                        <span class="font-normal">{{ message.author.role }}</span>
                    </span>
                </div>
                <div class="body p-4 rounded-lg bg-gray-200 mb-1">
                    <div
                        class="text"
                        v-html="purify(message.body.text)"
                    />
                </div>
                <div class="status-time flex font-ffdin text-gray-500 text-xs">
                    <icon
                        v-if="isMy && message.status === MESSAGE_STATUS_RECEIVED"
                        name="checkmark"
                        class="inline-block w-3.5 h-3.5 mr-2"
                    />
                    <icon
                        v-if="isMy && message.status === MESSAGE_STATUS_PENDING"
                        name="pending-circle"
                        class="inline-block w-3.5 h-3.5 mr-2 rotating"
                    />
                    <community-date-time-display
                        :input="message.timestamp"
                        format="h a"
                    />
                </div>
            </div>

            <button
                v-if="isMy && message.status === MESSAGE_STATUS_FAILED"
                @click="showRetryDialog = !showRetryDialog"
            >
                <icon
                    name="exclamation-circle"
                    class="block w-3.5 h-3.5 ml-2 text-red-600"
                />
            </button>
        </div>

        <div
            v-if="isMy && message.status === MESSAGE_STATUS_FAILED && showRetryDialog"
            ref="retryDialog"
            class="retry-dialog flex justify-end mt-2"
        >
            <div class="shadow-md flex flex-col items-start p-4 pr-10 m-0.5 rounded">
                <button
                    class="mb-4"
                    @click="handleRetryClick"
                >
                    <icon
                        name="retry"
                        class="inline-block w-3 h-3 text-gray-400 mr-4"
                    />
                    {{ $t('app.retry_action') }}
                </button>
                <button
                    class="text-red-600"
                    @click="handleDeleteClick"
                >
                    <icon
                        name="trash"
                        class="inline-block w-3 h-3 mr-4"
                    />
                    {{ $t('app.delete_action') }}
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { sanitize } from 'dompurify';
import {
    MESSAGE_STATUS_FAILED,
    MESSAGE_STATUS_PENDING,
    MESSAGE_STATUS_RECEIVED,
    MESSAGE_TYPE_SIMPLE,
    NOTE_TYPE_COST_ALLOCATION,
    NOTE_TYPE_ORDINARY,
    USER_TYPE_QUEXT,
} from '@/views/serviceRequests/constants';
import CommunityDateTimeDisplay from '@/components/ui/CommunityDateTimeDisplay';
import Icon from '@/components/ui/Icon';
import moment from 'moment-timezone';

export default {
    name: 'Message',
    components: { Icon, CommunityDateTimeDisplay },
    props: {
        message: {
            type: Object,
            required: true,
        },

        unread: {
            type: Boolean,
        },
    },

    emits: ['retry', 'delete'],

    data() {
        return {
            NOTE_TYPE_ORDINARY,
            NOTE_TYPE_COST_ALLOCATION,
            MESSAGE_STATUS_PENDING,
            MESSAGE_STATUS_FAILED,
            MESSAGE_STATUS_RECEIVED,
            showRetryDialog: false,
        };
    },

    computed: {
        ...mapGetters({
            cognitoUserId: 'auth/cognitoUserId',
        }),

        isMy() {
            return this.message.author.id.identityId === this.cognitoUserId;
        },

        fromPropertyStaff() {
            return this.message.author.id.userType === USER_TYPE_QUEXT;
        },

        typeIconName() {
            switch (this.message.type) {
            case MESSAGE_TYPE_SIMPLE:
                return 'message';
            default:
                return 'file-text';
            }
        },
    },

    watch: {
        showRetryDialog(val) {
            if (val) {
                this.$nextTick(() => {
                    this.$refs.retryDialog.scrollIntoView({ behavior: 'smooth' });
                });
            }
        },
    },

    methods: {
        purify(htmlString) {
            return sanitize(htmlString);
        },

        prepareDayString(date) {
            return moment(date).format('MM/DD/YYYY');
        },

        handleRetryClick() {
            this.showRetryDialog = false;
            this.$emit('retry');
        },

        handleDeleteClick() {
            this.showRetryDialog = false;
            this.$emit('delete');
        },
    },
};
</script>

<style scoped>
.message {
    @apply flex flex-col items-start relative;
    max-width: 80%;
    word-break: break-word;
}

.message.my {
    @apply ml-auto items-end;
}

.message.from-property .type-author .author {
    @apply text-green-700;
}

.message.my .type-author {
    @apply hidden;
}

.message.from-property .body {
    @apply bg-green-300;
}

.point {
    @apply absolute bg-green-600 rounded;
    width: 0.4375rem;
    height: 0.4375rem;
    left: -0.9375rem;
    top: 2.25rem;
}

.body .text {
    font-size: 14px;
}

.message:not(.my) .status-time .icon {
    @apply hidden;
}

.note-type {
    font-size: 11px;
}

.cost-allocation-details .text {
    @apply font-sofia font-medium text-lg mb-4;
}

@keyframes rotation {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.rotating {
    animation-name: rotation;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}

.retry-dialog {
    font-size: 14px;
}
</style>
