<template>
    <Page
        v-if="apiData"
        :title="apiData.metadata.category?.name"
        title-class="text-purple-900 mb-6 mt-4 mx-3"
        content-class=""
    >
        <div class="h-full">
            <Tabs
                :force-tab="forceTab"
                class="flex h-full flex-col"
                navClass="mx-9 mb-4"
            >
                <Tab
                    :title="$t('sreq.details.title')"
                    class="flex-grow h-full overflow-auto"
                >
                    <request-details
                        v-model:api-data="apiData"
                        :alarm-code-status="alarmCodeStatus"
                    />
                </Tab>
                <Tab
                    :title="$t('sreq.feed.title')"
                    class="flex-grow h-full overflow-auto"
                >
                    <request-feed
                        :chat-id="apiData.metadata.chat.id"
                        :description="apiData.data.description"
                    />
                </Tab>
            </Tabs>
        </div>
    </Page>
</template>

<script>
import Page from '@/components/ui/Page';
import Tabs from '@/components/ui/tabs/Tabs';
import Tab from '@/components/ui/tabs/Tab';
import RequestDetails from '@/components/sreq/RequestDetails';
import RequestFeed from '@/components/sreq/RequestFeed';
import NotifyMixin from '@/mixins/NotifyMixin';

export default {
    components: { RequestFeed, RequestDetails, Tab, Tabs, Page },
    mixins: [NotifyMixin],
    data() {
        return {
            forceTab: 0,
            apiData: null,
        };
    },

    mounted() {
        const { requestId } = this.$route.params;
        this.$sreqDataProvider.getOne('serviceRequests', { id: requestId }).then(response => {
            this.apiData = response;
        });
        this.$sreqDataProvider
            .getOne('communitySettings')
            .then(({ alarmCode }) => {
                this.alarmCodeStatus = alarmCode;
            })
            .catch(error => {
                this.notifyError(error.message);
            });

        this.forceTab = this.$route.hash === '#feed' ? 1 : 0;
    },
};
</script>
