<template>
    <Form
        ref="form"
        :initial-values="initialValues"
        :submit="() => {}"
        @change.self="handleFormChange"
    >
        <template v-slot="formProps">
            <ContentArea v-if="request">
                <text-collapse
                    :text="request.description"
                    class="mb-4"
                />

                <div
                    v-if="!request.sharedLocation"
                    class="mb-10"
                >
                    <router-link
                        :to="{ name: 'serviceRequests.timeInOutLog', params: { requestId: request.id } }"
                        class="text-purple-700 text-md2 font-ffdin font-medium underline"
                    >
                        {{ $t('sreq.time_in_out_logs.title') }}
                    </router-link>
                </div>

                <div class="info-grid grid grid-cols-2 mb-4">
                    <div class="mb-6">
                        <div class="label">{{ $t('sreq.fields.status') }}</div>
                        <div
                            class="data flex items-center"
                            :class="statusColorClass"
                        >
                            <icon
                                v-if="request.status.id === STATUS_COMPLETED"
                                name="checkmark"
                                class="inline-block w-6 h-6 -ml-2"
                            />
                            <icon
                                v-if="request.status.id === STATUS_CANCELED"
                                name="cancel"
                                class="inline-block w-3.5 h-3.5 mr-1"
                            />
                            {{ $t(`sreq.status.${request.status.id}`) }}
                        </div>
                    </div>
                    <template v-if="request.status.id === STATUS_COMPLETED || request.status.id === STATUS_CANCELED">
                        <div class="mb-6">
                            <div class="label">
                                {{ $t(request.status.id === STATUS_COMPLETED ? 'sreq.fields.completed_by' : 'sreq.fields.canceled_by') }}
                            </div>
                            <div class="data">
                                {{ request.statusChangedBy ? getFullName(request.statusChangedBy) : $t('app.system') }}
                            </div>
                        </div>
                        <div class="mb-6">
                            <div class="label">
                                {{
                                    $t(
                                        request.status.id === STATUS_COMPLETED
                                            ? 'sreq.fields.completion_date'
                                            : 'sreq.fields.cancellation_date'
                                    )
                                }}
                            </div>
                            <div class="data">
                                <community-date-time-display
                                    :input="request.statusChangedAt"
                                    format="MM/DD/YYYY"
                                />
                            </div>
                        </div>
                    </template>
                    <div class="mb-6">
                        <div class="label">{{ $t('sreq.fields.submitted_by') }}</div>
                        <div class="data">{{ getFullName(request.submittedBy) }}</div>
                    </div>
                    <div class="mb-6">
                        <div class="label">{{ $t('sreq.fields.submitted_date') }}</div>
                        <div class="data">
                            <community-date-time-display
                                :input="request.dateSubmitted"
                                format="MM/DD/YYYY"
                            />
                        </div>
                    </div>
                    <template v-if="request.statusSchema === 'ADVANCED'">
                        <div class="mb-6">
                            <div class="label">{{ $t('sreq.fields.assigned_to') }}</div>
                            <div v-if="request.assignees && request.assignees.length">
                                <div
                                    v-for="assignee in request.assignees"
                                    :key="assignee.id"
                                    class="data"
                                >
                                    {{ assignee.firstName }} {{ assignee.lastName }}
                                </div>
                            </div>
                            <div
                                v-else
                                class="data"
                            >
                                -
                            </div>
                        </div>
                        <div class="mb-6">
                            <div class="label">{{ $t('sreq.fields.scheduled_date') }}</div>
                            <div class="data">{{ request.schedule || '-' }}</div>
                        </div>
                    </template>
                </div>

                <div class="flex align-baseline justify-between mb-4">
                    <span class="section-title">{{ $t('sreq.fields.attachments') }}</span>
                    <span
                        v-if="formProps.values.attachments.length > 5"
                        class="text-sm text-purple-700 font-bold underline uppercase cursor-pointer"
                        @click="expandAttachments = true"
                    >
                        {{ $t('attachments.see_all', { num: formProps.values.attachments.length }) }}
                    </span>
                </div>
                <AttachmentsField
                    v-model:expanded="expandAttachments"
                    class="mb-10"
                    name="attachments"
                    :validate="composeValidators(maxAttachmentsNumber(20))"
                    :file-dialog-options="fileDialogOptions"
                    :disabled="!canEditAttachments"
                    @save="handleAttachmentsSaveClick"
                />

                <div class="section-title mb-4">{{ $t('sreq.location.title') }}</div>
                <div class="flex flex-row flex-wrap mb-7">
                    <div
                        v-for="(location, index) of request.locationsChain"
                        :key="index"
                        class="location-block flex flex-row mb-3"
                    >
                        <div
                            v-if="index > 1"
                            class="newline-arrow flex justify-center items-center w-4"
                        >
                            <icon
                                name="arrow-left"
                                class="arrow-icon w-2 h-2 text-gray-500"
                            />
                        </div>
                        <div class="location p-4 font-medium bg-gray-200">
                            <div class="font-ffdin text-xs text-gray-500">
                                <template v-if="location.type === LOCATION_TYPE_BUILDING">{{ $t('sreq.location.building') }}</template>
                                <template v-else-if="location.type === LOCATION_TYPE_UNIT">{{ $t('sreq.location.unit') }}</template>
                                <template v-else-if="location.type === LOCATION_TYPE_APARTMENT">{{
                                    $t('sreq.location.apartment')
                                }}</template>
                                <template v-else-if="location.type === LOCATION_TYPE_CUSTOM">{{
                                    $t('sreq.location.other_location')
                                }}</template>
                                <template v-else>{{ $t('sreq.location.location_level', { n: index + 1 }) }}</template>
                            </div>
                            <div class="font-sofia">{{ location.name }}</div>
                        </div>
                        <div
                            v-if="index < request.locationsChain.length - 1"
                            class="flex justify-center items-center w-4"
                        >
                            <icon
                                name="arrow-left"
                                class="arrow-icon w-2 h-2 text-gray-500"
                            />
                        </div>
                    </div>
                </div>

                <template v-if="!request.sharedLocation">
                    <div class="label mb-3">{{ $t('sreq.enter_permission.title') }}</div>
                    <div class="mb-10">
                        <template v-if="canEditEnterPermission">
                            <FinalField name="enterPermissionGranted">
                                <template v-slot="fieldProps">
                                    <label class="switch-label mb-4">
                                        <input
                                            v-model="fieldProps.value"
                                            class="switch"
                                            type="checkbox"
                                            @change="
                                                () => {
                                                    fieldProps.change(fieldProps.value);
                                                }
                                            "
                                        />
                                        {{ $t(request.enterPermission ? 'app.yes' : 'app.no') }}
                                    </label>
                                </template>
                            </FinalField>
                        </template>
                        <template v-else>
                            <check-cross-text
                                :text="$t(request.enterPermission ? 'app.yes' : 'app.no')"
                                :value="request.enterPermission"
                                class="mb-4"
                            />
                        </template>
                        <div
                            v-if="formValues.enterPermissionGranted"
                            class="pets-infobox infobox flex items-baseline bg-red-200 border-red-300 p-4"
                        >
                            <icon
                                name="exclamation-circle"
                                class="w-3.5 h-3.5 mr-4 text-red-600"
                            />
                            {{ $t('sreq.enter_permission.notice') }}
                        </div>
                        <div class="mt-4">
                            <TextField
                                v-if="alarmCodeStatus"
                                class="mt-6"
                                :label="$t('sreq.fields.alarm_code')"
                                name="alarmCode"
                                :validate="maxLength(50)"
                                :edit-mode="canEditAlarmCode"
                                show-error-immediately
                            />
                            <div
                                v-if="formValues.alarmCode !== initialValues.alarmCode"
                                class="flex mt-4"
                            >
                                <button
                                    type="button"
                                    class="btn btn-secondary w-1/2"
                                    @click="resetAlarmCode"
                                >
                                    {{ $t('app.cancel_action') }}
                                </button>
                                <button
                                    type="button"
                                    class="btn btn-primary w-1/2"
                                    :disabled="formProps.invalid"
                                    @click="saveAlarmCode"
                                >
                                    {{ $t('app.save_action') }}
                                </button>
                            </div>
                        </div>
                    </div>
                </template>

                <div class="label mb-3">{{ $t('sreq.notifications.preferred_title') }}</div>
                <div class="mb-10">
                    <check-cross-text
                        :text="$t('sreq.notifications.email_value', { value: request.contactPreferences.email || '-' })"
                        :value="request.contactPreferences.preferredChannel === CHANNEL_EMAIL"
                        class="mb-2"
                    />
                    <check-cross-text
                        :text="$t('sreq.notifications.text_value', { value: request.contactPreferences.phone || '-' })"
                        :value="request.contactPreferences.preferredChannel === CHANNEL_PHONE"
                        class="mb-2"
                    />
                </div>

                <div class="label mb-3">{{ $t('sreq.notifications.additional_title') }}</div>
                <div class="mb-10">
                    <div class="text-sm mb-3">{{ $t('sreq.notifications.additional_description') }}</div>
                    <check-cross-text
                        :text="
                            $t('sreq.notifications.email_value', {
                                value: request.notificationPreferences.email || '-',
                            })
                        "
                        :value="request.notificationPreferences.sendEmail"
                        class="mb-2"
                    />
                    <check-cross-text
                        :text="$t('sreq.notifications.text_value', { value: request.notificationPreferences.phone || '-' })"
                        :value="request.notificationPreferences.sendText"
                        class="mb-2"
                    />
                </div>

                <div class="label mb-3">{{ $t('sreq.notifications.events_title') }}</div>
                <div class="mb-14">
                    <check-cross-text
                        :text="$t('sreq.notifications.status_change')"
                        :value="request.notificationPreferences.sendOnStatusChange"
                        class="mb-2"
                    />
                    <check-cross-text
                        :text="$t('sreq.notifications.new_chat_message')"
                        :value="request.notificationPreferences.sendOnNewChatMessage"
                        class="mb-2"
                    />
                </div>

                <button
                    v-if="canCancel"
                    class="btn border-red-600 text-red-600 w-full mb-10"
                    @click="cancelRequest"
                >
                    {{ $t('sreq.confirmation.cancel.action') }}
                </button>

                <div
                    v-if="decisionRequired"
                    class="accept-decline sticky bg-white border-t -bottom-4 p-6 -mx-6"
                >
                    <div class="text text-center mb-4">{{ $t('sreq.confirmation.approve.description') }}</div>
                    <div class="grid grid-cols-2 gap-4">
                        <button
                            class="btn border-red-600 text-red-600"
                            @click="declineRequest"
                        >
                            {{ $t('sreq.confirmation.decline.action') }}
                        </button>
                        <button
                            class="btn btn-primary"
                            @click="approveRequest"
                        >
                            {{ $t('sreq.confirmation.approve.action') }}
                        </button>
                    </div>
                </div>

                <loader
                    :loading="loading"
                    backdrop
                />
            </ContentArea>
        </template>
    </Form>
</template>

<script>
import { useI18n } from 'vue-i18n';
import CommunityTimezoneMixin from '@/mixins/CommunityTimezoneMixin';
import CommunityDateTimeDisplay from '@/components/ui/CommunityDateTimeDisplay';
import ContentArea from '@/components/ui/ContentArea';
import Icon from '@/components/ui/Icon';
import CheckCrossText from '@/components/ui/CheckCrossText';
import {
    CHANNEL_EMAIL,
    CHANNEL_PHONE,
    LOCATION_TYPE_BUILDING,
    LOCATION_TYPE_CUSTOM,
    LOCATION_TYPE_UNIT,
    LOCATION_TYPE_APARTMENT,
    STATUS_CANCELED,
    STATUS_COMPLETED,
    STATUS_RESIDENT_VALIDATION,
    MODIFICATION_RULE_READ_ONLY,
} from '@/views/serviceRequests/constants';
import ConfirmationMixin from '@/mixins/ConfirmationMixin';
import TextCollapse from '@/components/ui/TextCollapse';
import Form from '@/components/form/Form';
import AttachmentsField from '@/components/form/AttachmentsField';
import ValidatorMixin from '@/mixins/ValidatorMixin';
import { fileDialogOptionsDefaults } from '@/utils/files';
import AttachmentsUploadMixin from '@/mixins/AttachmentsUploadMixin';
import NotifyMixin from '@/mixins/NotifyMixin';
import { FinalField } from 'vue-final-form';
import TextField from '@/components/form/TextField';
import { isEqual, omit } from 'lodash-es';
import Loader from '@/components/ui/Loader';
import { mapGetters } from 'vuex';

const flattenLocationTree = (location, chain = []) => {
    const { type, name, locations } = location;
    chain.push({ type, name });
    const deeperLocation = Array.isArray(locations) ? locations[0] : null;
    return deeperLocation ? flattenLocationTree(deeperLocation, chain) : chain;
};

export default {
    components: {
        Loader,
        TextField,
        FinalField,
        AttachmentsField,
        Form,
        TextCollapse,
        ContentArea,
        CheckCrossText,
        Icon,
        CommunityDateTimeDisplay,
    },

    mixins: [AttachmentsUploadMixin, NotifyMixin, CommunityTimezoneMixin, ConfirmationMixin, ValidatorMixin],

    props: {
        apiData: {
            type: Object,
            required: true,
        },

        alarmCodeStatus: {
            type: Boolean,
            required: true,
        },
    },

    emits: ['update:apiData'],

    setup() {
        const { t } = useI18n();
        return { t };
    },

    data() {
        return {
            STATUS_RESIDENT_VALIDATION,
            STATUS_COMPLETED,
            STATUS_CANCELED,
            LOCATION_TYPE_BUILDING,
            LOCATION_TYPE_UNIT,
            LOCATION_TYPE_APARTMENT,
            LOCATION_TYPE_CUSTOM,
            CHANNEL_EMAIL,
            CHANNEL_PHONE,
            fileDialogOptions: fileDialogOptionsDefaults.SREQ,
            request: null,
            canEditAttachments: true,
            canEditEnterPermission: true,
            canEditAlarmCode: true,
            canCancel: false,
            decisionRequired: true,
            expandAttachments: false,
            initialValues: {
                enterPermissionGranted: true,
                attachments: [],
                alarmCode: null,
            },

            formValues: null,

            // overrides value in attachments upload mixin
            serviceId: 'SREQ',

            loading: false,
        };
    },

    computed: {
        ...mapGetters({
            currentProfile: 'auth/currentProfile',
            getOccupancyData: 'leasing/getOccupancyData',
        }),

        isOccupancyCurrent() {
            return this.getOccupancyData?.occupancyType === 'current';
        },

        // prettier-ignore
        statusColorClass() {
            switch (this.request.status.id) {
            case STATUS_RESIDENT_VALIDATION:
                return 'text-green-700';
            case STATUS_COMPLETED:
                return 'text-gray-500';
            case STATUS_CANCELED:
                return 'text-red-600';
            default:
                return null;
            }
        },
    },

    watch: {
        apiData(newData) {
            this.mapResponseData(newData);
        },
    },

    created() {
        this.mapResponseData(this.apiData);
    },

    mounted() {
        const { attachments } = this.apiData.data;
        if (attachments.length > 0) {
            this.$attachmentsDataProvider
                .getList('attachments', { ids: attachments.map(({ id }) => id), preview: true })
                .then(response => {
                    this.$refs.form.finalForm.change(
                        'attachments',
                        response.map(item => ({
                            ...item,
                            url: null,
                            previewUrl: item.url,
                            uploaded: true,
                            attached: true,
                            action: 'download',
                        }))
                    );
                })
                .catch(error => this.notifyError(error.message));
        }
    },

    updated() {
        const blocks = document.querySelectorAll('.location-block');
        if (blocks.length < 2) return;

        for (let i = 1; i < blocks.length; i++) {
            const currentBlock = blocks[i];
            const previousBlock = blocks[i - 1];

            if (currentBlock.offsetTop !== previousBlock.offsetTop) {
                currentBlock.classList.add('pre-arrow');
            }
        }
    },

    methods: {
        handleFormChange(state) {
            const previousValues = { ...this.formValues };
            this.formValues = state.values;
            const fieldsToOmit = ['attachments', 'alarmCode'];
            if (!isEqual(omit(previousValues, fieldsToOmit), omit(state.values, fieldsToOmit))) {
                if (this.$refs.form.finalForm.getState().valid) {
                    this.submitForm();
                }
            }
        },

        submitForm() {
            this.loading = true;

            this.$sreqDataProvider
                .update('serviceRequests', {
                    id: this.apiData.id,
                    data: {
                        data: {
                            enterPermissionGranted: this.formValues.enterPermissionGranted,
                            alarmCode: this.alarmCodeStatus ? this.formValues.alarmCode : null,
                            attachments: this.formValues.attachments.map(({ id }) => ({ id })),
                        },
                    },
                })
                .then(response => {
                    this.$emit('update:apiData', response);
                    this.notifySuccess(this.t('sreq.update.success.general'));
                })
                .catch(error => {
                    this.notifyError(error.message);
                })
                .finally(() => {
                    this.loading = false;
                });
        },

        mapResponseData(response) {
            this.request = {
                id: response.id,
                title: response.data.title,
                description: response.data.description,
                status: response.metadata.currentState,
                submittedBy: response.metadata.residentSpecifiedOnCreate
                    ? response.metadata.reporterResident
                    : response.metadata.reporterStaffUser,

                assignees: response.metadata.assignees,
                dateSubmitted: response.createdAt,
                schedule: response.metadata.schedule,
                locationsChain: flattenLocationTree(response.data.location),
                enterPermission: response.data.enterPermissionGranted,
                contactPreferences: response.data.contactPreferences,
                notificationPreferences: response.data.notificationPreferences,
                statusChangedAt: response.metadata.stateChangedAt,
                statusChangedBy: response.metadata.stateModifier,
                sharedLocation: response.data.sharedLocation,
                statusSchema: response.metadata.statusSchema,
                alarmCode: response.data.alarmCode,
                autoCompletedAt: response.metadata.autoCompletedAt,
            };

            if (response.data.customLocation) {
                this.request.locationsChain.push({ type: '', name: 'Other' }, { type: 'CUSTOM', name: response.data.customLocation });
            }

            this.canEditAttachments = this.isOccupancyCurrent && response.modificationRules.attachments !== MODIFICATION_RULE_READ_ONLY;
            this.canEditEnterPermission =
                this.isOccupancyCurrent && response.modificationRules.enterPermissionGranted !== MODIFICATION_RULE_READ_ONLY;
            this.canEditAlarmCode = this.isOccupancyCurrent && response.modificationRules.alarmCode !== MODIFICATION_RULE_READ_ONLY;
            this.canCancel = this.isOccupancyCurrent && response.modificationRules.cancelAllowed;
            this.decisionRequired = this.isOccupancyCurrent && response.modificationRules.decisionRequired;

            this.initialValues.enterPermissionGranted = response.data.enterPermissionGranted;
            this.initialValues.alarmCode = response.data.alarmCode;
            this.formValues = this.initialValues;
        },

        getFullName(person) {
            return person.firstName + ' ' + person.lastName;
        },

        async cancelRequest() {
            if (
                await this.requestConfirmation({
                    title: this.t('sreq.confirmation.cancel.title'),
                    text: this.t('sreq.confirmation.cancel.text'),
                    cancelCaption: this.t('sreq.confirmation.cancel.cancel_caption'),
                    confirmCaption: this.t('sreq.confirmation.cancel.confirm_caption'),
                })
            ) {
                try {
                    this.loading = true;
                    const response = await this.$sreqDataProvider.cancel('serviceRequests', { id: this.apiData.id });
                    this.$emit('update:apiData', response);
                    this.notifySuccess(this.t('sreq.update.success.cancel'));
                } catch (error) {
                    this.notifyError(error.message);
                } finally {
                    this.loading = false;
                }
            }
        },

        async declineRequest() {
            if (
                await this.requestConfirmation({
                    title: this.t('sreq.confirmation.decline.title'),
                    text: this.t('sreq.confirmation.decline.text'),
                    cancelCaption: this.t('sreq.confirmation.decline.cancel_caption'),
                    confirmCaption: this.t('sreq.confirmation.decline.confirm_caption'),
                })
            ) {
                try {
                    this.loading = true;
                    const response = await this.$sreqDataProvider.decline('serviceRequests', { id: this.apiData.id });
                    this.$emit('update:apiData', response);
                    this.notifySuccess(this.t('sreq.update.success.decline'));
                } catch (error) {
                    this.notifyError(error.message);
                } finally {
                    this.loading = false;
                }
            }
        },

        async approveRequest() {
            if (
                await this.requestConfirmation({
                    title: this.t('sreq.confirmation.approve.title'),
                    text: this.t('sreq.confirmation.approve.text'),
                    cancelCaption: this.t('sreq.confirmation.approve.cancel_caption'),
                    confirmCaption: this.t('sreq.confirmation.approve.confirm_caption'),
                })
            ) {
                try {
                    this.loading = true;
                    const response = await this.$sreqDataProvider.accept('serviceRequests', { id: this.apiData.id });
                    this.$emit('update:apiData', response);
                    this.notifySuccess(this.t('sreq.update.success.accept'));
                } catch (error) {
                    this.notifyError(error.message);
                } finally {
                    this.loading = false;
                }
            }
        },

        async handleAttachmentsSaveClick(items) {
            try {
                this.attachments = items;
                await this.uploadAttachments();

                await this.$sreqDataProvider.update('serviceRequests', {
                    id: this.request.id,
                    data: { data: { attachments: this.attachments.map(({ id }) => ({ id })) } },
                });

                this.attachments.forEach(item => {
                    item.attached = true;
                    item.action = 'download';
                });

                this.$refs.form.finalForm.change('attachments', this.attachments);
                this.expandAttachments = false;
            } catch (error) {
                this.attachments.filter(({ error }) => error).forEach(item => (item.action = 'retry-delete-menu'));
                this.$refs.form.finalForm.change('attachments', this.attachments);
                this.notifyError(error.message);
            }
        },

        resetAlarmCode() {
            this.$refs.form.finalForm.change('alarmCode', this.initialValues.alarmCode);
        },

        saveAlarmCode() {
            this.submitForm();
        },
    },
};
</script>

<style scoped>
.info-grid {
    column-gap: 1rem;
}

.location-block:not(.pre-arrow) .newline-arrow {
    display: none;
}

.location {
    border-radius: 8px;
    word-break: break-word;
}

.arrow-icon {
    transform: rotate(180deg);
}

.pets-infobox {
    font-size: 15px;
}

.accept-decline .text {
    font-size: 15px;
}

.accept-decline .btn + .btn {
    margin-left: 0;
}

.data {
    @apply break-all;
}
</style>
